import React from "react";

function ComoFunciona() {
  return (
    <div className="marginAuto">
      <h1>¿En qué nos diferenciamos?</h1>
      <p className="centerDiv">
        Nuestros competidores, como Trello, Jira y Monday, son herramientas de
        gestión de proyectos que NO se especializan en aumentar la productividad
        ni en la satisfacción del usuario dentro del contexto laboral chileno.
        Carecen de una integración profunda de IA que genere automatice y
        controle tareas y solicitudes, y su enfoque generalista no permite la
        implementación de sueldos variables y sistemas meritocráticos
        eficientes. Aunque útiles para la organización general de proyectos, no
        están enfocados a medir el rendimiento de los trabajadores, mientras que
        nuestra herramienta sí, ya que aplica IA lo que permite automatizar
        procesos y monitorizar en tiempo real las actividades asignadas y
        realizadas, brindando una solución más completa y especializada para el
        contexto laboral chileno.
      </p>
      <hr />
      <hr />
      <hr />
    </div>
  );
}

export default ComoFunciona;
