import React, { useState, useEffect } from "react";

function TitleText() {
  const [text, setText] = useState("");
  const [textIa, setTextIa] = useState("En línea");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(50);
  const [escribiendoClass, setEscribiendocClass] = useState("des");

  const toRotate = ["Eleva la productividad de tu equipo", "Aumenta la satisfacción de tus clientes", "Digitaliza tus operaciones", "Fija Sueldos Variables", "Mejora el tiempo de respuesta"];
  const period = 3000; // Tiempo que espera antes de empezar a borrar.

  useEffect(() => {
    let ticker;

    if (isDeleting) {
      setTextIa("En línea");
      setEscribiendocClass("des");
      ticker = setTimeout(() => {
        setText((currentText) =>
          currentText.substring(0, currentText.length - 40)
        );
        setTypingSpeed(100); // Velocidad de borrado más rápida
      }, typingSpeed);
    } else {
      setTextIa("IA está escribiendo...");
      setEscribiendocClass("act");
      ticker = setTimeout(() => {
        setText((currentText) =>
          toRotate[loopNum].substring(0, currentText.length + 1)
        );
        setTypingSpeed(100); // Restablece la velocidad normal cuando está escribiendo
      }, typingSpeed);
    }

    return () => {
      clearTimeout(ticker);
    };
  }, [text, isDeleting]);

  useEffect(() => {
    let timeout;
    if (!isDeleting && text === toRotate[loopNum]) {
      setTextIa("En línea");
      setEscribiendocClass("des");
      timeout = setTimeout(() => {
        setIsDeleting(true);
        setTypingSpeed(100); // Restablece velocidad de borrado
      }, period);
    } else if (isDeleting && text === "") {
      setIsDeleting(false);
      setLoopNum((loopNum) => (loopNum + 1) % toRotate.length);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [text, isDeleting, loopNum]);

  // Función para renderizar el texto con el cursor
  const renderTextWithCursor = () => {
    if (textIa === "IA está escribiendo...") {
      return (
        <span>
          {text}<span className="cursor"></span>
        </span>
      );
    } else {
      return <span>{text}</span>;
    }
  };

  return (
    <div className="marginAuto">
      <h1> {renderTextWithCursor()}</h1>
      <p className="centerDiv">
      <div className={`iaEscribiendo ${escribiendoClass}`}>🤖 {textIa}</div>

        Planius revoluciona la gestión de solicitudes y tareas, integrando
        Inteligencia Artificial para optimizar y medir el rendimiento de tus
        trabajadores. Más efectivo que los métodos convencionales, más intuitivo
        que las soluciones estándar y todo sincronizado en tiempo real.
      </p>
    </div>
  );
}

export default TitleText;
