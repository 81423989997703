import React from 'react'
import computerFrameImage from '../img/compu.png'; // Asegúrate de importar la imagen del marco de computadora

function VideoPorQue() {
    return (
        <div className="videoPorQueContainer">
        <div className="contentWrapper">
          <div className="videoFrameWrapper">
            <div className="videoWrapper">
              <iframe 
                width="550" 
                height="210" 
                src="https://www.youtube.com/embed/3nyVet3Q4Vw" 
                title="YouTube video player" 
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
              </iframe>
            </div>
          </div>
            <div className="divider"></div>
            <div className="textWrapper">
                <span className='whySubTitle'>¿POR QUÉ PLANIUS?</span>
              <h1 className='whyTitle'>Porque optimizamos y medimos rendimiento para recompensar eficacia laboral y mejorar la productividad</h1>
              <button className='contactButton'>Habla con nosotros</button>
            </div>
          </div>
        </div>
      );
}

export default VideoPorQue