import React from "react";
import Agent from "../img/agente.png";
import Cliente from "../img/clientes.png";
import Empresa from "../img/empresa.png";

function Caracteristicas() {
  return (
    <div>
      <h1 className="titleEco">¡Haz más en menos tiempo!</h1>
      <div className="tarjetas">
      <div className="caracteristica">
        <h4 className="titleCard">Asigna tareas con IA</h4>
        🤖 Incorpora nuestra exclusiva IA para asignar automáticamente las solicitudes a los colaboradores más aptos, mejorando la eficiencia y reduciendo los costos operacionales.
        </div>
        <div className="caracteristica">
          <h4 className="titleCard">Mide la productividad</h4>
        📊 Optimiza la gestión del tiempo y mide la productividad de tus colaboradores con análisis avanzados de desempeño, centrados en la calidad y rapidez de la respuesta.
        </div>

        <div className="caracteristica">
        <h4 className="titleCard">Premia a tu equipo</h4>
        🥳 Fomenta un ambiente laboral motivador con un sistema de recompensas personalizado, permitiendo a cada colaborador alcanzar metas y recibir premios específicos.
        </div>


        <div className="caracteristica">
        <h4 className="titleCard">Incentiva el teletrabajo</h4>
        👨‍💻 Canaliza las tareas en un sólo lugar y recibe informes en vivo, lo que te permitirá saber si tus trabajadores están cumpliendo con los plazos establecidos independientemente de la modalidad de trabajo.
        </div>

        <div className="caracteristica">
        <h4 className="titleCard">Mide la productividad</h4>
        💰 Flexibilidad económica garantizada. Nuestros planes se ajustan a la dinámica de tu negocio, basándose en el tamaño de tu equipo y tu negocio.
        </div>

      </div>
    </div>
  );
}

export default Caracteristicas;
