import React, { useState, useEffect } from "react";
import imgKanban from "../img/kanbanSoportick.png";
import imgOtra1 from "../img/dashboardPersonalizado.png";
import imgOtra2 from "../img/chat.png";
import solicitudes from "../img/solicitudes.png"
import parametrizacion from "../img/parametrización.png"
import gamificacion from "../img/gamificación.png"
import solicitudCliente from "../img/solicitudCliente.png"
import parametrizaIA from "../img/paramIA.png"

function Metodologia() {
  // Un array de objetos, cada uno con una imagen y el texto correspondiente
  const items = [
    { imagen: imgKanban, texto: "Aplicamos Metodología Kanban para gestionar tareas" },
    { imagen: imgOtra1, texto: "Medimos KPI's para cada colaborador y elaboramos informes de productividad" },
    { imagen: imgOtra2, texto: "Chat personalizado para cada solicitud" },
    { imagen: solicitudes, texto: "Creación de solicitudes asistida por IA, asignando responsables y creando planes de trabajo" },
    { imagen: parametrizacion, texto: "Parametrización individual para cada colaborador y cada cliente" },
    { imagen: gamificacion, texto: "Incluimos un sistema de gamificación profunda para motivar a tus colaboradores" },
    { imagen: solicitudCliente, texto: "Recibe solicitudes de tus clientes directamente en Soportick. ¡Hazlos parte del proceso y canaliza todo en un sólo lugar!" },
    { imagen: parametrizaIA, texto: "Permitimos que puedas parametrizar tu IA para que sepa qué compentencias realiza tu negocio y así canalizar las solicitudes de forma automática" },

  ];
  
  // Estado para manejar el índice del objeto actual
  const [indiceActual, setIndiceActual] = useState(0);

  useEffect(() => {
    // Crea un intervalo para cambiar al siguiente objeto cada 2 segundos
    const intervalo = setInterval(() => {
      setIndiceActual((indiceActual) => (indiceActual + 1) % items.length);
    }, 2000);

    // Limpia el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalo);
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar

  return (
    <div className="marginAuto2">
      <h1>{items[indiceActual].texto}</h1>
      <div className="contenedorImagenCentrada">
        <img
          src={items[indiceActual].imagen}
          alt="Imagen Descriptiva"
          className="imagenCentrada"
        />
      </div>
    </div>
  );
}

export default Metodologia;
