import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../img/logo.png';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const navMenu = document.querySelector('.nav-menu');

    if (menuOpen) {
      navMenu.classList.add('show');
    } else {
      navMenu.classList.remove('show');
    }
  }, [menuOpen]);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const goToPoliticas = () => {
    navigate('/politicas');
  };

  return (
    <nav>
      <button className="hamburger-menu" onClick={handleMenuClick}>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
      </button>
      <ul className="nav-menu">
        <li>
          <div className="nav_logo"></div>
        </li>
        <li>
          <a href="#home">Inicio</a>
        </li>
        <li>
          <a href="#howWorks">¿Cómo funciona?</a>
        </li>
        <li>
          <a href="#caracteristicas">Diferenciación</a>
        </li>
        <li>
          <a href="#contact">Características</a>
        </li>
        <li>
          <a className="initButton" href="https://app.planius.ai">
            Inicia Sesión
          </a>
        </li>
        <li>
          <a className="startButton" href="">
            Conversemos
          </a>
        </li>
        <li>
          <button className="initButton" onClick={goToPoliticas}>Políticas</button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
