import React from "react";

function ComoFunciona() {
  return (
    <div className="marginAuto">
      <h1>¿Qué es Planius?</h1>
      <p className="centerDiv">
        Es una plataforma que incentiva la transformación digital de procesos
        incorporando Inteligencia Artificial. Administra y canaliza solicitudes de clientes logrando así
        optimizar el rendimiento laboral y la atención en diversos sectores,
        promoviendo trabajo remoto y aumentando la eficiencia mediante sueldos
        variables, permitiendo medir el rendimiento de los trabajadores. De esta
        forma mejora la productividad y la satisfacción, automatiza tareas, crea
        alertas, y promueve un entorno justo y meritocrático.
      </p>
    </div>
  );
}

export default ComoFunciona;
