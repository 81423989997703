import React from 'react'
function Beneficios() {
    return (
        <div id="beneficios">
          <h1>Quienes usan Planius experimentan</h1>
          <div className="facts-container">
            <div className="fact">
              <p className="big-number">x10</p>
              <p>Mejoran su productividad hasta 10 veces</p>
            </div>
            <div className="fact">
              <p className="big-number">98%</p>
              <p>Menos solicitudes vencidas</p>
            </div>
            <div className="fact">
              <p className="big-number">80%</p>
              <p>Más teletrabajo</p>
            </div>
          </div>
          <div>

          </div>
        </div>
      );
}

export default Beneficios