import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./components/Navbar";
import TitleText from "./components/TitleText";
import ComoFunciona from "./components/ComoFunciona";
import Caracteristicas from "./components/Caracteristicas";
import Diferenciacion from "./components/Diferenciacion";
import VideoPorQue from "./components/VideoPorQue";
import Beneficios from "./components/Beneficios";
import Metodologia from "./components/Metodologia";
import Politicas from "./components/Politicas";
import "./style.scss";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // duración de la animación, puedes ajustar esto según tus necesidades
    });
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/politicas" element={<Politicas />} />
      </Routes>
    </Router>
  );
}

function Home() {
  return (
    <>
      <Navbar />
      <section className="home" id="home">
        <TitleText />
        <a href="#howWorks" className="cta-button">
          Descubre más
        </a>
      </section>

      <section className="home" data-aos="fade" id="howWorks">
        <ComoFunciona />
      </section>

      <section className="home" data-aos="fade-up" id="porque">
        <VideoPorQue />
      </section>

      <section className="home" id="diferenciacion">
        <Diferenciacion />
      </section>

      <section className="home" id="metodologia">
        <Metodologia />
      </section>

      <section data-aos="slide-right" className="home" id="caracteristicas">
        <Caracteristicas />
      </section>

      <section className="home" id="beneficios">
        <Beneficios />
      </section>
      <footer>
        <p>&copy; 2024 Planius Ai. Todos los derechos reservados.</p>
      </footer>
    </>
  );
}

export default App;
